<template>
  <div class="header">
    <h1>{{ this.$t("mypage.review") }}</h1>
    <ul class="path">
      <li>
        <router-link to="/mypage">{{ this.$t("mypage.mypage") }}</router-link>
      </li>
      <li class="current">
        <router-link to="/mypage/review">{{
          this.$t("mypage.review")
        }}</router-link>
      </li>
    </ul>
  </div>
  <div class="body">
    <div class="tab_wrap">
      <div class="tab selected">{{ this.$t("mypage.review") }}</div>
      <div class="tab" @click="this.$router.push('/mypage/qna')">
        {{ this.$t("mypage.qna") }}
      </div>
    </div>
    <div class="search_wrap">
      <div class="date">
        <input type="date" v-model="this.search_start_date" /><span>-</span
        ><input type="date" v-model="this.search_end_date" />
        <button class="button middle red search" @click="click_search">
          {{ this.$t("common.search") }}
        </button>
      </div>
    </div>

    <!-- review -->
    <ul class="review">
      <li v-if="this.review_data.length <= 0" class="empty">
        {{ this.$t("product.detail.no_review") }}
      </li>
      <li v-for="(value, index) in this.review_data" :key="index">
        <div class="top">
          <div class="id">
            <router-link
              :to="{ path: '/product/detail/' + value.product_id }"
              >{{ value.product_name }}</router-link
            >
          </div>
          <div class="date">
            {{
              this.common.date_long_format(
                this.common.date_parse(value.register_date)
              )
            }}
          </div>
        </div>
        <div class="body">
          <Rating
            :rate="value.review_score"
            :readonly="true"
            class="score"
          ></Rating>
          <div class="spec">
            <ul>
              <li>{{ value.product_option }}</li>
            </ul>
          </div>
          <div class="comment">{{ value.review_content }}</div>
          <div class="image_wrap">
            <div
              class="image"
              :style="{
                backgroundImage: 'url(' + value.review_image_url1 + ')',
              }"
              @click="this.open_image_zoom(value.review_image_url1)"
              v-if="value.review_image_url1 != ''"
            ></div>
            <div
              class="image"
              :style="{
                backgroundImage: 'url(' + value.review_image_url2 + ')',
              }"
              @click="this.open_image_zoom(value.review_image_url2)"
              v-if="value.review_image_url2 != ''"
            ></div>
            <div
              class="image"
              :style="{
                backgroundImage: 'url(' + value.review_image_url3 + ')',
              }"
              @click="this.open_image_zoom(value.review_image_url3)"
              v-if="value.review_image_url3 != ''"
            ></div>
          </div>
        </div>
      </li>
    </ul>
    <!--// review -->

    <button
      class="button outline small sharp compact more"
      v-if="this.review_data.length && !this.data_fully_loaded"
      @click="load_review_data"
    >
      {{ this.$t("common.load_more") }}
    </button>
  </div>

  <Transition name="fade">
    <div
      class="overlay"
      v-if="this.show_image_zoom"
      @click="this.close_image_zoom"
    ></div>
  </Transition>

  <Transition name="fade">
    <div class="image_zoom" v-if="this.show_image_zoom">
      <div class="close" @click="this.close_image_zoom"></div>
      <img :src="this.image_zoom_src" />
    </div>
  </Transition>

  <Spinner v-show="processing" />
</template>

<script>
import Rating from "../product/Rating.vue";
export default {
  name: "MyReview",
  components: {
    Rating,
  },
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.myreview"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.myreview"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      data_fully_loaded: false,
      search_start_date: "",
      search_end_date: "",
      search_page: 1,
      review_data: [],
      review_total_count: 0,

      // 이미지 확대 관련
      show_image_zoom: false,
      image_zoom_src: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_review_data();
  },
  methods: {
    // 리뷰 데이터 가져오기
    load_review_data: function () {
      if (!this.storage.is_logged_in()) return;

      // 이미 다 불러왔으면 진행안함.
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/review/list",
          {
            user_id: this.storage.get_user_id(),
            search_start_date: this.search_start_date,
            search_end_date: this.search_end_date,
            page: this.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 첫 페이지에서는 데이터 초기화
          if (self.search_page <= 1) {
            self.review_data = [];
          }

          // 리뷰 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              resp_data[i].review_image_url1 = "";
              resp_data[i].review_image_url2 = "";
              resp_data[i].review_image_url3 = "";

              if (
                resp_data[i].review_image1 != null &&
                resp_data[i].review_image1 != ""
              ) {
                resp_data[i].review_image_url1 =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/review/" +
                  resp_data[i].review_id +
                  "/" +
                  resp_data[i].review_image1;
              }

              if (
                resp_data[i].review_image2 != null &&
                resp_data[i].review_image2 != ""
              ) {
                resp_data[i].review_image_url2 =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/review/" +
                  resp_data[i].review_id +
                  "/" +
                  resp_data[i].review_image2;
              }

              if (
                resp_data[i].review_image3 != null &&
                resp_data[i].review_image3 != ""
              ) {
                resp_data[i].review_image_url3 =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/review/" +
                  resp_data[i].review_id +
                  "/" +
                  resp_data[i].review_image3;
              }

              self.review_data.push(resp_data[i]);
            }

            if (resp_data.length <= 0) {
              self.data_fully_loaded = true;
            } else {
              self.search_page++; // 페이지 수 늘리기
            }

            // 총 갯수 정보 헤더에서 가져오기
            try {
              let str_info = response.headers.wkt_extra_info;
              let extra_info = JSON.parse(str_info);
              self.review_total_count = parseInt(extra_info.total_count);
            } catch (e) {
              console.log(e);
            }
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_review_data

    // 검색 버튼 이벤트 핸들러
    click_search: function () {
      this.search_page = 1;
      this.data_fully_loaded = false;

      this.load_review_data();
    },

    close_image_zoom: function () {
      this.show_image_zoom = false;
      this.image_zoom_src = "";
    },
    open_image_zoom: function (img_url) {
      this.show_image_zoom = true;
      this.image_zoom_src = img_url;
    },
  },
};
</script>

<style scoped>
.body > .tab_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 0 2rem 0;

  border-bottom: 1px solid #333;
}
.body > .tab_wrap > .tab {
  width: 50%;
  padding: 1rem 1.3rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  background: #f4f4f4;
  cursor: pointer;
}
.body > .tab_wrap > .tab.selected {
  background: #333;
  color: #fff;
}

.body > .search_wrap {
  padding: 1.2rem 1.6rem;
  margin: 0 0 1rem 0;
  border-radius: 0.8rem;
  background: #f4f4f4;
}
.body > .search_wrap > .date {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.body > .search_wrap > .date > input[type="date"] {
  width: 35%;
}
.body > .search_wrap > .date > span {
  margin: 0 1rem;
}
.body > .search_wrap > .date > button.button {
  letter-spacing: 0;
  width: 20%;
  margin-left: 1rem;
  font-size: 1rem;
  padding: 0.8rem;
}

.body > ul.review {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.body > ul.review > li {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #dedede;
}
.body > ul.review > li.empty {
  text-align: center;
  padding: 3rem 0;
}
.body > ul.review > li > .top {
  position: relative;
}
.body > ul.review > li > .top > .id {
  font-weight: 700;
  padding: 0.8rem 0;
}
.body > ul.review > li > .top > .date {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);

  padding: 0.8rem 0;
  color: #808080;
}
.body > ul.review > li > .top a {
  color: #000;
  text-decoration: none;
}
.body > ul.review > li > .top a:hover {
  text-decoration: underline;
}

.body > ul.review > li > .body {
  position: relative;
  padding: 0.8rem 0;
  min-height: 96px;
}
.body > ul.review > li > .body > .score {
  width: 120px;
  margin-bottom: 0.8rem;
}
.body > ul.review > li > .body > .spec {
  padding: 0 8rem 0.8rem 0;
}
.body > ul.review > li > .body > .spec > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.body > ul.review > li > .body > .spec > ul > li {
  position: relative;
  padding: 0 1rem 0 0;
  margin: 0 1rem 0 0;
  color: #808080;
}
.body > ul.review > li > .body > .spec > ul > li::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  background: #ccc;

  width: 1px;
  height: 60%;
}
.body > ul.review > li > .body > .spec > ul > li:last-child:after {
  display: none;
}
.body > ul.review > li > .body > .comment {
  padding: 0.8rem 8rem 0 0;
  white-space: pre-line;
}
.body > ul.review > li > .body > .image_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.8rem 0 0 0;
}
.body > ul.review > li > .body > .image_wrap > .image {
  width: 86px;
  height: 86px;
  margin: 0 0.4rem 0 0;
  background: #dedede;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}
.body > ul.review > li > .body > .image {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  width: 86px;
  height: 86px;
  background: #dedede;
}

.body > button.more {
  display: block;
  margin: 2rem auto 0 auto;
}

.image_zoom {
  position: fixed;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  max-width: 90vw;
  max-height: 90vh;
  z-index: 99;
}
.image_zoom > .close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);

  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;

  background: #333 url("../../assets/img/icon_close_white.svg") no-repeat center
    center;
  background-size: auto 60%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  cursor: pointer;
}
.image_zoom > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
</style>
